<template>
  <div class="section">
    <ModalHeader
      :left-text="'Kembali'"
      :middle-text="'Detail Info'"
      :left-function="directPage"
      :is-rigt-column="false"
    />
    <b-container class="mt-5 pt-5">
      <b-row class="justify-content-center h-100 bg-white">
        <b-col
          cols="12"
          md="6"
        >
          <b-row>
            <b-col
              cols="12"
              class="custom__form--input"
            >
              <h2 class="text-black fw-bold-800 mb-1">{{ userData.name }}</h2>
              <hr class="my-2">
            </b-col>
            <!-- Email -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                Nama
              </label>
              <h6 class="text-dark size18 fw-bold-600">
                {{ result.name }}
              </h6>
            </b-col>
            <!-- No. Telepon -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                No. Telepon
              </label>
              <h6 class="text-dark size18 fw-bold-600">
                {{ result.phone_number }}
              </h6>
            </b-col>
            <!-- No. NPWP -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                No. NPWP
              </label>
              <h6 class="text-dark size18 fw-bold-600">
                {{ result.tax_number }}
              </h6>
            </b-col>
            <!-- Alamat -->
            <b-col
              cols="12"
              md="12"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                Alamat
              </label>
              <h6 class="text-dark size18 fw-bold-600" v-html="result.address">
              </h6>
            </b-col>
            <!-- Nama Sales -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                Nama Sales
              </label>
              <h6 class="text-dark size18 fw-bold-600">
                {{ result.sales_name }}
              </h6>
            </b-col>
            <!-- No. Telepon Sales -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                No. Telepon Sales
              </label>
              <h6 class="text-dark size18 fw-bold-600">
                {{ result.sales_phone_number }}
              </h6>
            </b-col>
            <!-- Pembayaran -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                Pembayaran
              </label>
              <h6 class="text-dark size18 fw-bold-600">
                {{ result.payment_type }}
              </h6>
            </b-col>
            <!-- Jumlah Hari -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                Jumlah Hari
              </label>
              <h6 class="text-dark size18 fw-bold-600">45 Hari</h6>
            </b-col>
          </b-row>
          <b-col cols="12" class="px-0">
            <div class="d-flex justify-content-between mb-1">
              <h5 class="text-black-2 fw-bold-800 size16 mb-0">
                Daftar Rekening (1)
              </h5>
            </div>
            <b-card
              class="p-1 border-12 d-flex flex-row justify-content-between"
              style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);"
              no-body
            >
              <div class="d-flex">
                <b-img
                  width="120"
                  height="72"
                  style="object-fit:cover"
                  class="border-8"
                  :src="require('@/assets/images/icons/banks/bca.svg')"
                />
                <div class="ml-1">
                  <h6 class="text-dark text-darken-5 fw-bold-400 size14">
                    Tabungan
                  </h6>
                  <h5 class="text-black-2 fw-bold-800 size16">
                    288-3493-3343
                  </h5>
                  <span class="text-dark fw-bold-500 size14 mb-0">a.n. Soetomo Wiriatmodjo</span>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol, BCard, BImg,
  // BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

// import UploadPhoto from '@/components/UploadPhoto.vue'
import ModalHeader from '@/components/ModalHeader.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BImg,
    ModalHeader,
    // BFormGroup,
    // BFormInput,
    // BFormTextarea,
    // UploadPhoto,
    // vSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      result: this.$store.state.supplier.result,
    }
  },
  mounted() {
  },
  methods: {
    directPage() {
      this.$store.commit('customer/setResult', {})
      this.$bvModal.hide('modal-supplier')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
// @import '@core/scss/vue/libs/vue-select.scss';


.bg-white.btn-secondary:focus {
  background-color: transparent !important;
}

.btn-type {
  background-color: #EFF1F5 !important;
  border: 1px solid #E4E5EC !important;
  border-radius: 8px;
  margin-right: 15px;
  color: $dark !important;

  &.active {
    background-color: #FFFFFF !important;
    border: 1px solid #89A7E0 !important;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04);
    color: #2E5AAC !important;
  }
}
</style>
