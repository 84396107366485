<template>
  <b-modal
    id="modal-supplier"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <keep-alive>
      <component
        :is="comp"
        :get-data="getData"
      />
    </keep-alive>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

import AddSupplier from '@/components/Customer/Modal/Part/AddSupplier.vue'
import DetailSupplier from '@/components/Customer/Modal/Part/DetailSupplier.vue'

export default {
  components: {
    BModal,
    AddSupplier,
    DetailSupplier,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    // eslint-disable-next-line vue/prop-name-casing
    is_edit: {
      type: Boolean,
      default: null,
    },
    getData: {
      type: Function,
    },
  },
  data() {
    return {
      comp: 'AddSupplier',
    }
  },
  watch: {
    is_edit(value) {
      if (value === true) {
        this.comp = 'AddSupplier'
      } else {
        this.comp = 'DetailSupplier'
      }
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
