<template>
  <b-col cols="12">
    <div class="d-flex justify-content-between mb-1">
      <h5 class="text-black-2 fw-bold-800 size16 mb-0">
        Daftar Rekening ({{ result.total }})
      </h5>
      <b-button
        id="button--tambah__rekening"
        class="text-dark fw-bold-800 size14 bg-white"
        @click="$bvModal.show('modal-add-rekening')"
      >
        + Tambah Rekening
      </b-button>
    </div>
    <b-card
      v-for="(bank, index) in result.data"
      :key="`banks-${index}`"
      class="p-1 border-12 d-flex flex-row justify-content-between"
      style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);"
      no-body
    >
      <div class="d-flex">
        <b-img
          width="120"
          height="72"
          style="object-fit:cover"
          class="border-8"
          :src="bank.bank.logo_url"
        />
        <div class="ml-1">
          <h6 class="text-dark text-darken-5 fw-bold-400 size14">
            {{ bank.account_type }}
          </h6>
          <h5 class="text-black-2 fw-bold-800 size16">
            {{ bank.account_number }}
          </h5>
          <span class="text-dark fw-bold-500 size14 mb-0">a.n. {{ bank.owner_name }}</span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div>
          <b-button
            id="button--edit__data"
            class="bg-white mr-1 px-3"
            @click="editItem(bank)"
          >
            <b-img :src="require('@/assets/images/icons/Process2.svg')" /> Ubah
          </b-button>
          <b-button
            id="button--delte__data"
            class="border-0 border-8 btn-delete bg-pink p-1"
            @click="deleteData(bank.uuid)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-danger"
            />
          </b-button>
        </div>
      </div>
    </b-card>
    <ModalAddRekening
      :form-data="formPayload"
      :create-item="createItem"
      :edit-id="editId"
      :edit-item="editItem"
      :update-item="updateItem"
      :clean-up-form="cleanUpForm"
      :getDataBankAccounts="getDataBankAccounts"
      :options="options"
      :bank="bank"
      @getPayload="getPayload"
    />
  </b-col>
</template>

<script>
import {
  BCol, BButton, BCard, BImg,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import ModalAddRekening from '@/components/Master/Toko/Modal/ModalAddRekening.vue'

export default {
  components: {
    BCol,
    BButton,
    BCard,
    BImg,
    ModalAddRekening,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    reloadData: {
      type: Function,
    },
    banks: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      editId: null,
      isLoading: true,
      result: {},
      options: [],
      formPayload: {
        bank_uuid: '',
        owner_name: '',
        account_number: '',
        account_type: '',
        supplier_uuid: this.$store.state.supplier.editId,
      },
      bank: {},
      messages: '',
    }
  },
  created() {
    this.getDataBankAccounts()
  },
  methods: {
    ...mapActions('bankAccounts', ['getData']),
    getPayload(value) {
      this.formPayload = value
    },
    async getDataBankAccounts() {
      this.isLoading = true
      await this.getData({
        params: {
          supplier_uuid: this.$store.state.supplier.editId,
        },
      })
        .then(result => {
          this.result = result.data.data
          this.getDataBank()
          this.isLoading = false
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    async getDataBank() {
      await this.$store.dispatch('bank/getData', {
        uuid: '',
        params: '',
      })
        .then(result => {
          // console.log(result.data.data.data)
          result.data.data.data.forEach(element => {
            this.options.push({
              label: element.uuid,
              title: element.name,
              logo: element.logo_url,
            })
          })
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    cleanUpForm() {
      this.editId = null
      this.bank = {
        logo: '',
        name: '',
      }
      this.formPayload = {
        bank_uuid: '',
        owner_name: '',
        account_number: '',
        account_type: '',
        supplier_uuid: '',
      }
    },
    editItem(item) {
      this.cleanUpForm()
      this.bank = {
        logo: item.bank.logo_url,
        name: item.bank.name,
      }
      this.editId = item.uuid
      this.formPayload = {
        bank_uuid: item.bank.uuid,
        owner_name: item.owner_name,
        account_number: item.account_number,
        account_type: item.account_type,
        supplier_uuid: this.$store.state.supplier.editId,
      }
      this.$bvModal.show('modal-add-rekening')
    },
    createItem() {
      this.formPayload.supplier_uuid = this.$store.state.supplier.editId
      const form = this.preparePayload()
      this.isLoading = true
      this.$store.dispatch('bankAccounts/postData', {
        query: '',
        payload: form,
      })
        .then(() => {
          this.reloadData()
          this.getDataBankAccounts()
          successNotification(this, 'Success', 'Akun bank berhasil di tambahkan')
          this.cleanUpForm()
          this.$bvModal.hide('modal-add-rekening')
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    updateItem() {
      this.formPayload.supplier_uuid = this.$store.state.supplier.editId
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      this.$store.dispatch('bankAccounts/postData', {
        query: `/${this.editId}`,
        payload: form,
      })
        .then(() => {
          this.reloadData()
          this.getDataBankAccounts()
          successNotification(this, 'Success', 'Akun bank berhasil di ubah!')
          this.cleanUpForm()
          this.isLoading = false
          this.$bvModal.hide('modal-add-rekening')
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    async deleteData(uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this bank account?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('bankAccounts/deleteData', `/${uuid}`)
            .then(() => {
              this.reloadData()
              this.getDataBankAccounts()
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Akun bank berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }
      return form
    },
  },
}
</script>
